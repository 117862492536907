import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import AssetForm from './assets/AssetForm'
import { Form } from 'react-final-form'
import { mapFinalFormErrors } from '../lib/utils'
import { createAsset, updateAsset, fetchTeammembers,fetchActiveUsers } from '../middleware/api'
import { cryptInfo } from '../config'


const mapErrors = mapFinalFormErrors('Asset creation failed.')

class AddAssetModal extends React.Component {
    state = {
        employees: null,
        isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
        isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
    }

    onSubmit = (values) => {
        const { assets, fetchAssets, toggle } = this.props
        values.status = "CREATED"
        values.allocatedon = new Date()
        const { employees } = this.state
        const value = employees && employees.filter((member) => {
            return member.value === values.empid
        })
        values.empname = value[0].label
        if (assets && assets._id) {
            updateAsset(assets._id, values)
                .then(async () => {
                    await fetchAssets()
                    toggle()
                })
        } else {
            createAsset(values)
                .then(async (response) => {
                    await fetchAssets()
                })
                .then(toggle())
                .catch(mapErrors)
        }
    }


    getTeamember() {
        const userId = localStorage.getItem('user_id')
        fetchTeammembers(userId)
            .then(async (response) => {
                const result = await response.json()
                let teamMemberList = result && result.map((t) => {
                    return { value: t._id, label: t.fname + ' ' + t.lname }
                })
                this.setState({
                    employees: teamMemberList
                })
            })
    }
    getAllUsers = () => {
        fetchActiveUsers()
            .then(async (response) => {
                const result = await response.json()
                let employeesData = result && result.map((t) => {
                    return { value: t._id, label: t.fname + ' ' + t.lname }
                })
                this.setState({
                    employees: employeesData
                })
            })
    }


    componentDidMount() {
        const {isAdmin,isManager} = this.state
        isManager &&  this.getTeamember()
        isAdmin && this.getAllUsers()
    }
    render() {

        const { isOpen, toggle, assets } = this.props
        const { isAdmin, employees } = this.state
        const modalTitle = assets ? "Edit Asset" : "Add Asset"
        return (
            <div>
                <Modal isOpen={isOpen} toggle={toggle} className={this.props.className}>
                    <ModalHeader
                        tag="h2"
                        toggle={toggle}>{modalTitle}</ModalHeader>
                    <ModalBody>
                        <Form
                            component={AssetForm}
                            id="addasset"
                            toggle={toggle}
                            onSubmit={this.onSubmit}
                            subscription={{
                                pristine: true,
                                submitError: true,
                                submitting: true,
                                hasValidationErrors: true,
                            }}
                            employees={employees && employees}
                            update={assets}
                            isAdmin={isAdmin}
                            initialValues={
                                {
                                    empid: assets && assets.empid ? assets.empid : "",
                                    assesttype: assets && assets.assesttype ? assets.assesttype : "",
                                    serial: assets && assets.serial ? assets.serial : "",
                                    specification: assets && assets.specification ? assets.specification : "",
                                    remark: assets && assets.remark ? assets.remark : "",

                                }
                            }

                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default AddAssetModal;