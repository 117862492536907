import { API_CALL } from '../../src/config'
const apiRoot = `${API_CALL}/api/v1`

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
}

export const fetchProjects = () => {
  const url = `${apiRoot}/projects`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const userDailyTiming = (values) => {
  const url = `${apiRoot}/reports`
  let method = HTTP_METHODS.POST
  let body = values
  return callApi(url, method, body)
}
export const fetchUserReport = (userId) => {
  const url = `${apiRoot}/reports/user/${userId}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchAllUserReportWithManager = (user_id) => {
  const url = `${apiRoot}/reports/manager/${user_id}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchUserDailyTiming = (report) => {
  const url = `${apiRoot}/reports/today`
  let method = HTTP_METHODS.POST
  let body = report
  return callApi(url, method, body)
}
export const fetchLogs = (days) => {
  const url = `${apiRoot}/reports/${days}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchUserlogs = (userId,days) => {
  const url = `${apiRoot}/reports/users/${userId}/${days}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const updateReport = (reportId, report) => {
  const url = `${apiRoot}/reports/${reportId}`
  let method = HTTP_METHODS.PUT
  let body = report
  return callApi(url, method, body)
}
export const createProject = (values) => {
  const url = `${apiRoot}/projects`
  let method = HTTP_METHODS.POST
  let body = values
  return callApi(url, method, body)
}
export const updateProject = (projectId, project) => {
  const url = `${apiRoot}/projects/${projectId}`
  let method = HTTP_METHODS.PUT
  let body = project
  return callApi(url, method, body)
}

export const fetchHolidays = () => {
  const url = `${apiRoot}/holidays`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchTodayHolidays = () => {
  const url = `${apiRoot}/holidays/today`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchTodayBirthdays = () => {
  const url = `${apiRoot}/users/birthdays`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchTodayAnniversary = () => {
  const url = `${apiRoot}/users/anniversary`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchTodayJoiningAnniversary = () => {
  const url = `${apiRoot}/users/joininganniversary`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const createHoliday = (values) => {
  const url = `${apiRoot}/holidays`
  let method = HTTP_METHODS.POST
  let body = values
  return callApi(url, method, body)
}
export const updateHoliday = (holidayId, holiday) => {
  const url = `${apiRoot}/holidays/${holidayId}`
  let method = HTTP_METHODS.PUT
  let body = holiday
  return callApi(url, method, body)
}
export const deleteHoliday = (holidayId) => {
  const url = `${apiRoot}/holidays/${holidayId}`
  let method = HTTP_METHODS.DELETE
  let body = null
  return callApi(url, method, body)
}

export const loginUser = (userData) => {
  const url = `${apiRoot}/users/login`
  let method = HTTP_METHODS.POST
  let body = userData
  return callApi(url, method, body)
}
export const fetchUsers = () => {
  const url = `${apiRoot}/users`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchActiveUsers = () => {
  const url = `${apiRoot}/users/active`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchTeammembers = (id) => {
  const url = `${apiRoot}/users/teammember/${id}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchUser = (id) => {
  const url = `${apiRoot}/users/${id}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const updateUser = (userId, user) => {
  const url = `${apiRoot}/users/${userId}`
  let method = HTTP_METHODS.PUT
  let body = user
  return callApi(url, method, body)
}
export const deleteUser = (userId) => {
  const url = `${apiRoot}/users/${userId}`
  let method = HTTP_METHODS.DELETE
  let body = null
  return callApi(url, method, body)
}
export const fetchManagers = () => {
  const url = `${apiRoot}/users/managers`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const createUser = (values) => {
  const url = `${apiRoot}/users`
  let method = HTTP_METHODS.POST
  let body = values
  return callApi(url, method, body)
}
export const fetchTasks = () => {
  const url = `${apiRoot}/tasks`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchActiveTasks = () => {
  const url = `${apiRoot}/tasks/active`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const createTask = (values) => {
  const url = `${apiRoot}/tasks`
  let method = HTTP_METHODS.POST
  let body = values
  return callApi(url, method, body)
}
export const updateTask = (taskId, task) => {
  const url = `${apiRoot}/tasks/${taskId}`
  let method = HTTP_METHODS.PUT
  let body = task
  return callApi(url, method, body)
}
export const fetchTimelogs = () => {
  const url = `${apiRoot}/timelogs`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchSubmittedTimelogs = () => {
  const url = `${apiRoot}/timelogs/submitted`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}

export const fetchUserTimelogs = (userId) => {
  const url = `${apiRoot}/timelogs/${userId}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}

export const fetchManagerTimesheet = (userId) => {
  const url = `${apiRoot}/timelogs/manager-timesheet/${userId}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchManagerAllTimesheet = (userId) => {
  const url = `${apiRoot}/timelogs/manager-timesheet-all/${userId}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchCurrentTimesheet = (values) => {
  const url = `${apiRoot}/timelogs/current-timesheet`
  let method = HTTP_METHODS.POST
  let body = values
  return callApi(url, method, body)
}
export const createTimesheet = (values) => {
  const url = `${apiRoot}/timelogs`
  let method = HTTP_METHODS.POST
  let body = values
  return callApi(url, method, body)
}
export const updateCompleteTimesheet = (id, timesheet) => {
  const url = `${apiRoot}/timelogs/${id}`
  let method = HTTP_METHODS.PUT
  let body = timesheet
  return callApi(url, method, body)
}
export const updateTimesheet = (timesheet) => {
  const url = `${apiRoot}/timelogs/${timesheet._id}`
  let method = HTTP_METHODS.PUT
  let body = timesheet
  return callApi(url, method, body)
}

export const fetchProjectManagers = () => {
  const url = `${apiRoot}/users/project-manager`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchLeaves = (param) => {
  const url = `${apiRoot}/leaves${param ? `?${param}` : ""}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchUserLeaves = (id) => {
  const url = `${apiRoot}/leaves/${id}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchAllPendingLeaves = () => {
  const url = `${apiRoot}/leaves/pending`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchUserLeavesLimit = (id) => {
  const url = `${apiRoot}/leaves/limit/${id}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchManagerLeave = (userId) => {
  const url = `${apiRoot}/leaves/manager-leave/${userId}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchAvailedLeaveFormanager = (userId) => {
  const url = `${apiRoot}/leaves/availed-leave/${userId}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchAvailedLeaveForUser = (userId) => {
  const url = `${apiRoot}/leaves/availed/${userId}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchAvailedLeaveForAdmin = () => {
  const url = `${apiRoot}/leaves/balance`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchAllManagerLeaves = (userId,param) => {
  const url = `${apiRoot}/leaves/manager-leave-all/${userId}${param ? `?${param}` : ""}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const createLeave = (values) => {
  const url = `${apiRoot}/leaves`
  let method = HTTP_METHODS.POST
  let body = values
  return callApi(url, method, body)
}
export const updateLeave = (leaveId, leave) => {
  const url = `${apiRoot}/leaves/${leaveId}`
  let method = HTTP_METHODS.PUT
  let body = leave
  return callApi(url, method, body)
}
export const deleteLeave = (leaveId) => {
  const url = `${apiRoot}/leaves/${leaveId}`
  let method = HTTP_METHODS.DELETE
  let body = null
  return callApi(url, method, body)
}
export const sendMailToAdmin = (data) => {
  const url = `${apiRoot}/mail/toadmin`
  let method = HTTP_METHODS.POST
  let body = data
  return callApi(url, method, body)
}

export const sendInvestmentMail = (data) => {
  const url = `${apiRoot}/mail/investment`
  let method = HTTP_METHODS.POST
  let body = data
  return callApi(url, method, body)
}
export const sendApprovalMail = (data) => {
  const url = `${apiRoot}/mail/leave/approval`
  let method = HTTP_METHODS.POST
  let body = data
  return callApi(url, method, body)
}

export const addUserMail = (data) => {
  const url = `${apiRoot}/mail/send/user-added`
  let method = HTTP_METHODS.POST
  let body = data
  return callApi(url, method, body)
}
export const kraMail = (data) => {
  const url = `${apiRoot}/mail/send/kramail`
  let method = HTTP_METHODS.POST
  let body = data
  return callApi(url, method, body)
}
export const wishMail = (data) => {
  const url = `${apiRoot}/mail/send/wishes`
  let method = HTTP_METHODS.POST
  let body = data
  return callApi(url, method, body)
}
export const createKRA = (values) => {
  const url = `${apiRoot}/kras`
  let method = HTTP_METHODS.POST
  let body = values
  return callApi(url, method, body)
}
export const updateKRA = (kraId, kra) => {
  const url = `${apiRoot}/kras/${kraId}`
  let method = HTTP_METHODS.PUT
  let body = kra
  return callApi(url, method, body)
}
export const fetchKRAs = () => {
  const url = `${apiRoot}/kras`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchUserKRAs = (id) => {
  const url = `${apiRoot}/kras/${id}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchSubmittedKRAs = (id) => {
  const url = `${apiRoot}/kras/submitted/${id}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchFeedbackKRAs = (id) => {
  const url = `${apiRoot}/kras/feedback/${id}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchManagerProject = (userId) => {
  const url = `${apiRoot}/projects/manager/${userId}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchManagerKRA = (id) => {
  const url = `${apiRoot}/kras/manager/${id}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchClients = () => {
  const url = `${apiRoot}/clients`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchActiveClients = () => {
  const url = `${apiRoot}/clients/active`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const createClient = (values) => {
  const url = `${apiRoot}/clients`
  let method = HTTP_METHODS.POST
  let body = values
  return callApi(url, method, body)
}
export const updateClient = (clientId, client) => {
  const url = `${apiRoot}/clients/${clientId}`
  let method = HTTP_METHODS.PUT
  let body = client
  return callApi(url, method, body)
}
export const deleteClient = (clientId) => {
  const url = `${apiRoot}/clients/${clientId}`
  let method = HTTP_METHODS.DELETE
  let body = null
  return callApi(url, method, body)
} 
export const fetchAssets = () => {
  const url = `${apiRoot}/assets`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchTeammemberAsset = (userId) => {
  const url = `${apiRoot}/assets//manager-asset/${userId}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const fetchUserAssets = (id) => {
  const url = `${apiRoot}/assets/${id}`
  let method = HTTP_METHODS.GET
  let body = null
  return callApi(url, method, body)
}
export const createAsset = (values) => {
  const url = `${apiRoot}/assets`
  let method = HTTP_METHODS.POST
  let body = values
  return callApi(url, method, body)
}
export const updateAsset = (assetId, asset) => {
  const url = `${apiRoot}/assets/${assetId}`
  let method = HTTP_METHODS.PUT
  let body = asset
  return callApi(url, method, body)
}
export const deleteAsset = (assetId) => {
  const url = `${apiRoot}/assets/${assetId}`
  let method = HTTP_METHODS.DELETE
  let body = null
  return callApi(url, method, body)
}
export const loggingOut = () => {
  if (!localStorage.getItem('isSignedin') || localStorage.getItem('isSignedin') === false) {
    localStorage.clear()
    window.location.reload()
  }
}
const callApi = (url, method, body) => {
  var requestOptions = {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token'),
    }
  }
  if (body !== null) {
    requestOptions.body = JSON.stringify(body)
  }
  return fetch(url, requestOptions)
}
