import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import ProjectForm from './projects/ProjectForm'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { filterManagerAndAdmin } from '../data/dataFromApis'
import { fetchActiveUsers, createProject, createClient, updateProject, fetchClients } from '../middleware/api'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
class AddProjectModal extends React.Component {
    state = {
        project: null,
        users: null,
    }

    componentDidMount() {
        fetchActiveUsers()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    users: result
                })
            })

    }
    formatClients = (clients) => {
        let clientArray = []
        clients && clients.forEach(function (client) {
            clientArray.push({
                value: client._id,
                label: client.clientname
            })
        })
        clientArray.push({ label: 'Other (New Client)', value: 'add_new' })
        return clientArray
    }
    onSubmit = async (values) => {

        const { fetchProjects, getClients, project, toggle } = this.props
        if (values && values.client === 'add_new' && values.newClient !== '') {
            await createClient({ clientname: values.newClient })
                .then(async (response) => {
                    const result = await response.json()
                    values.client = result._id
                    if (result.error === true) {
                        toast.error("Error: Client already exist.")
                    } else {
                        await fetchClients()
                        toast.success("Success: New client successfully added.")
                    }
                })
        }
        var startDate = new Date(values.startdate)
        var endDate = values.enddate ? new Date(values.enddate) : ""

        if (endDate && (startDate.getTime() > endDate.getTime())) {
            return { [FORM_ERROR]: "End date can't be smaller than start date." }
        }

        if (project && project._id) {
            await updateProject(project._id, values)
                .then(async () => {
                    await fetchProjects()
                    await getClients()
                    toggle()
                    toast.success("Success: Project successfully updated.")
                })
        } else {
            await createProject(values)
                .then(async () => {
                    await fetchProjects()
                    await getClients()
                    toggle()
                    toast.success("Success: Project successfully added.")
                })
        }
    }

    render() {

        const { isOpen, toggle, project, className, clients } = this.props
        const { users } = this.state
        var formattedClientArray = clients && this.formatClients(clients)
        const modalTitle = project ? "Edit Project" : "Add Project"
        let adminAndManagers = users && filterManagerAndAdmin(users)
        return (
            <div>
                <Modal id="project-modal" isOpen={isOpen} toggle={toggle} className={className}>
                    <ModalHeader
                        tag="h2"
                        toggle={toggle}> {modalTitle}</ModalHeader>
                    <ModalBody>
                        <Form
                            component={ProjectForm}
                            formId="addproject"
                            toggle={toggle}
                            onSubmit={this.onSubmit}
                            subscription={{
                                pristine: true,
                                submitError: true,
                                submitting: true,
                                hasValidationErrors: true,
                                values: true
                            }}
                            mutators={{
                                ...arrayMutators,
                            }}
                            adminAndManagers={adminAndManagers && adminAndManagers}
                            clientArray={formattedClientArray && formattedClientArray}
                            update={project}
                            initialValues={
                                {
                                    projectname: project && project.projectname ? project.projectname : "",
                                    client: project && project.client ? project.client : "",
                                    status: project && project.status ? project.status : "",
                                    projectmanagerrole: project && project.projectmanagerrole ? project.projectmanagerrole : "",
                                    projectmanager: project && project.projectmanager ? project.projectmanager : "",
                                    startdate: project && project.startdate ? project.startdate : "",
                                    enddate: project && project.enddate ? project.enddate : "",
                                    type: project && project.type ? project.type : "",
                                    teammember: project && project.teammember ? project.teammember : []
                                }
                            }
                            handleStartDate={this.handleStartDate}
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default AddProjectModal;