import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import { NavItem, NavLink, Nav, Row, Col, Container, Button } from 'reactstrap'
import Leaves from '../components/leaves/Leaves'
import TeammemberView from '../components/leaves/TeammemberView'
import LeaveBalance from '../components/leaves/TeamMemberLeaveBalance'
import LeaveAvailed from '../components/leaves/MemberLeaveAvailed'
import ApplyLeaveModal from '../components/ApplyLeaveModal'
import {
    fetchLeaves,
    fetchUserLeaves,
    loggingOut,
    fetchUser,
    fetchHolidays,
    fetchTeammembers,
    fetchAllManagerLeaves,
    fetchActiveUsers,
    fetchAvailedLeaveFormanager,
    fetchAvailedLeaveForAdmin,
    fetchAvailedLeaveForUser
} from '../middleware/api'
import { cryptInfo } from '../config'
import PageHeading from '../components/content/PageHeading'
import EmployeeDetailModal from '../components/EmployeeDetailModal'
import Loader from '../components/content/Loader'
import moment from 'moment'

class LeaveContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openApplyLeaveModal: false,
            leaves: null,
            leave: null,
            showEmployeeView: true,
            showManagerView: false,
            leaveBalanceView: false,
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
            userLeaves: null,
            isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
            user: null,
            holidays: null,
            isOpen: true,
            teamMember: null,
            leaveAvailedView: false,
            leaveAvailed: null,
            searchLeave: '',
            selectedEmployee: null,
            openDetailModal: false,
            loading: false,
            formError: null,
            openFilterModal: false,
            filterQuery: {
                user: null,
                type: null,
                from: null,
                to: null,
                status: null
            }
        }
        this.getLeaves = this.getLeaves.bind(this)
        this.getUserLeaves = this.getUserLeaves.bind(this)
        this.getUser = this.getUser.bind(this)
        this.toggle = this.toggle.bind(this)
        this.setTimings = this.setTimings.bind(this)
        this.getTeamMembers = this.getTeamMembers.bind(this)
        this.getManagerLeaves = this.getManagerLeaves.bind(this)
        this.filterUpdated = this.filterUpdated.bind(this)
    }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
    setTimings(time) {
        this.setState({
            timers: time
        })
    }
    setError = (formError) => {
        this.setState({
            formError
        })
    }

    toggleEmployeeView = () => {
        this.getUserLeaves()
        this.setState({
            showEmployeeView: true,
            showManagerView: false,
            leaveBalanceView: false,
            leaveAvailedView: false

        })
    }
    toggleManagerView = () => {
        this.setState({
            showEmployeeView: false,
            showManagerView: true,
            leaveBalanceView: false,
            leaveAvailedView: false
        })
    }
    toggleLeaveBalanceView = () => {
        this.setState({
            showEmployeeView: false,
            showManagerView: false,
            leaveBalanceView: true,
            leaveAvailedView: false
        })
    }
    toggleLeaveAvailedView = () => {
        this.availedLeaveFormanager()
        this.setState({
            showEmployeeView: false,
            showManagerView: false,
            leaveBalanceView: false,
            leaveAvailedView: true
        })
    }

    toggleModal = () => {
        const { openApplyLeaveModal } = this.state
        this.setState(state => ({
            openApplyLeaveModal: !state.openApplyLeaveModal,
            leave: null
        }))
        if (openApplyLeaveModal)
            this.setState({ formError: null })
    }

    toggleEditModal = (leave) => {
        this.toggleModal()
        this.setState({
            leave: leave
        })
    }
    getLeaves(param) {
        fetchLeaves(param ? param : null)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    leaves: result
                })
            })
    }

    getManagerLeaves(param) {
        const userId = localStorage.getItem('user_id')
        fetchAllManagerLeaves(userId, param ? param : null)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    leaves: result
                })
            })
    }

    getUser() {
        const userId = localStorage.getItem('user_id')
        fetchUser(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    user: result
                })
            })
    }
    getUserLeaves() {
        const userId = localStorage.getItem('user_id')
        fetchUserLeaves(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    userLeaves: result
                })
            })
    }
    getTeamMembers() {
        const userId = localStorage.getItem('user_id')
        fetchTeammembers(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    teamMember: result
                })
            })
    }
    availedLeaveFormanager() {
        const userId = localStorage.getItem('user_id')
        const { isAdmin, isManager } = this.state
        if (isAdmin || isManager) {
            isManager && fetchAvailedLeaveFormanager(userId)
                .then(async (response) => {
                    const result = await response.json()
                    this.setState({
                        leaveAvailed: result
                    })
                })
            isAdmin && fetchAvailedLeaveForAdmin()
                .then(async (response) => {
                    const result = await response.json()
                    this.setState({
                        leaveAvailed: result
                    })
                })
        } else {
            fetchAvailedLeaveForUser(userId)
                .then(async (response) => {
                    const result = await response.json()
                    this.setState({
                        leaveAvailed: result
                    })
                })
        }

    }
    getAllUsers() {
        fetchActiveUsers()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    teamMember: result
                })
            })
    }
    filterUpdated(newData) {
        this.setState({
            leaveAvailed: newData
        })
    }
    componentDidMount() {
        this.refreshList()
        fetchHolidays()
            .then(async (response) => {
                var result = await response.json()
                var holidayArray = []
                result && result.forEach(holiday => {
                    holidayArray.push(holiday.date)
                });
                this.setState({
                    holidays: holidayArray
                })
            })
    }

    toggleEmployeeDetail = (selectedEmployee) => {
        if (selectedEmployee) {
            fetchUser(selectedEmployee)
                .then(async (response) => {
                    var result = await response.json()
                    this.setState({ selectedEmployee: result })
                })
        }
        this.setState((prevStat) => ({
            openDetailModal: !prevStat.openDetailModal
        }))
    }

    handleFilterPopup = () => {
        this.setState((prev) => ({
            openFilterModal: !prev.openFilterModal
        }))
    }

    refreshList = () => {
        this.setState({ loading: true })
        const { isAdmin, isManager } = this.state
        loggingOut()
        isAdmin && this.getLeaves(null)
        this.getUserLeaves()
        isManager && this.getManagerLeaves(null)
        isAdmin && this.getAllUsers()
        this.getUser()
        isManager && this.getTeamMembers()
        this.setState({ loading: false })
    }

    makeUrlString = (values) => {
        var urlString = ""
        if (values.user)
            urlString += `user=${values.user}&`
        if (values.type)
            urlString += `type=${values.type}&`
        if (values.from)
            urlString += `from=${values.from}&`
        if (values.to)
            urlString += `to=${values.to}&`
        if (values.status)
            urlString += `status=${values.status}`
        return urlString
    }

    onApplyFilter = (values) => {

        const { isAdmin, isManager, filterQuery } = this.state
        if (!values) {
            this.setState({ loading: true })
            isAdmin && this.getLeaves(null)
            isManager && this.getManagerLeaves(null)
            this.setState({
                loading: false,
                filterQuery: {
                    user: null,
                    from: null,
                    to: null,
                    type: null,
                    status: null
                }
            })
            return false
        } else {
            let urlString = this.makeUrlString(values)
            this.setState({ loading: true })
            isAdmin && this.getLeaves(urlString)
            isManager && this.getManagerLeaves(urlString)
            this.handleFilterPopup()
            this.setState({
                loading: false,
                filterQuery: {
                    ...values,
                    from: values.from ? moment(values.from).format('YYYY-MM-DD') : filterQuery.from,
                    to: values.to ? moment(values.to).format('YYYY-MM-DD') : filterQuery.to
                }
            })
        }

    }

    render() {
        const { openApplyLeaveModal,
            leave,
            leaves,
            isAdmin,
            showEmployeeView,
            showManagerView,
            leaveBalanceView,
            userLeaves,
            user,
            holidays,
            isOpen,
            teamMember,
            isManager,
            leaveAvailedView,
            leaveAvailed,
            openFilterModal,
            selectedEmployee, openDetailModal, formError,
            filterQuery } = this.state
        const filterleaves = leaves && leaves
        return (
            <>
                <div className="App wrapper hide-overflow">
                    <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
                    <Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
                        {(!showEmployeeView && !showManagerView) && <PageHeading title={showEmployeeView ? 'My Leaves' : leaveAvailedView ? "Leave Balance Report" : "Team Member Leaves"} />}
                        {(showEmployeeView || showManagerView) &&
                            <Container fluid>
                                <Row className="page-heading">
                                    <Col className="text-left">
                                        <span>{showManagerView ? "Team Member Leaves" : "My Leaves"}</span>
                                    </Col>
                                    <Col className="text-right">
                                        <Button color="warning" size="lg" onClick={this.toggleModal}>
                                            Apply Leave
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        }
                        <div className="container-fluid pt-5">
                            <Nav tabs className="menu-items">
                                <NavItem>
                                    <NavLink
                                        onClick={this.toggleEmployeeView}
                                        className={showEmployeeView ? "active" : ''}>
                                        My Leaves
                                    </NavLink>
                                </NavItem>

                                {
                                    (isAdmin || isManager) &&
                                    <>
                                        <NavItem>
                                            <NavLink
                                                onClick={this.toggleManagerView}
                                                className={showManagerView ? "active" : ''}>
                                                Team Member Leaves
                                            </NavLink>
                                        </NavItem>
                                    </>
                                }
                                <NavItem>
                                    <NavLink
                                        onClick={this.toggleLeaveAvailedView}
                                        className={leaveAvailedView ? "active" : ''}>
                                        Leave Balance Report
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            {this.state.loading ? <Loader /> : <>
                                {
                                    showManagerView && isAdmin &&
                                    <TeammemberView
                                        leaves={filterleaves && filterleaves}
                                        toggleModal={this.toggleModal}
                                        fetchLeaves={this.getLeaves}
                                        users={teamMember}
                                        filterQuery={filterQuery}
                                        handleFilterPopup={this.handleFilterPopup}
                                        onApplyFilter={this.onApplyFilter}
                                        openFilterModal={openFilterModal}
                                        toggleEditModal={this.toggleEditModal}
                                        toggleEmployeeDetail={this.toggleEmployeeDetail}
                                    />
                                }
                                {
                                    showManagerView && isManager &&
                                    <TeammemberView
                                        leaves={filterleaves && filterleaves}
                                        toggleModal={this.toggleModal}
                                        users={teamMember}
                                        filterQuery={filterQuery}
                                        openFilterModal={openFilterModal}
                                        onApplyFilter={this.onApplyFilter}
                                        handleFilterPopup={this.handleFilterPopup}
                                        fetchLeaves={this.getManagerLeaves}
                                        toggleEditModal={this.toggleEditModal}
                                        toggleEmployeeDetail={this.toggleEmployeeDetail}
                                    />
                                }
                                {
                                    showEmployeeView &&
                                    <Leaves
                                        leaves={userLeaves && userLeaves}
                                        toggleModal={this.toggleModal}
                                        fetchLeaves={this.getUserLeaves}
                                        toggleEditModal={this.toggleEditModal}
                                        user={user && user}
                                        getUser={this.getUser}
                                    />
                                }
                                {
                                    leaveBalanceView && isAdmin &&
                                    <LeaveBalance
                                        teamMember={teamMember && teamMember}
                                        fetchLeaves={this.getTeamMembers}
                                        toggleEmployeeDetail={this.toggleEmployeeDetail}
                                    />
                                }
                                {
                                    leaveBalanceView && isManager &&
                                    <LeaveBalance
                                        teamMember={teamMember && teamMember}
                                        fetchLeaves={this.getTeamMembers}
                                        toggleEmployeeDetail={this.toggleEmployeeDetail}
                                    />
                                }
                                {
                                    leaveAvailedView &&
                                    <LeaveAvailed
                                        leaveData={leaveAvailed && leaveAvailed}
                                        filterUpdated={this.filterUpdated}
                                        toggleEmployeeDetail={this.toggleEmployeeDetail}
                                    />
                                }
                            </>}
                        </div>
                        <ApplyLeaveModal
                            isOpen={openApplyLeaveModal}
                            leaves={userLeaves && userLeaves}
                            toggle={this.toggleModal}
                            fetchLeaves={isManager ? this.getManagerLeaves : isAdmin ? this.getLeaves : this.getUserLeaves}
                            leave={leave}
                            refreshList={this.refreshList}
                            user={user && user}
                            isManager={isManager || isAdmin}
                            holidays={holidays && holidays}
                            teamMember={teamMember && teamMember}
                            formError={formError}
                            setError={this.setError}
                        />

                        <EmployeeDetailModal
                            isOpen={openDetailModal}
                            toggle={this.toggleEmployeeDetail}
                            userDetails={selectedEmployee}
                        />

                    </Content>
                </div>
            </>
        );
    }
}

export default LeaveContainer;