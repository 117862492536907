import React, { Component } from "react";
import { Button, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faEye, faTimes } from "@fortawesome/free-solid-svg-icons";
import { userRole } from "../data/userRolesData";
import { fetchProjects, updateUser } from "../middleware/api";
import { fetchEmployeeFamilyMembers } from "../middleware/familymember";
import { ToastContainer, toast } from "react-toastify";
import EmployeeDetailModal from "./EmployeeDetailModal";
import "react-toastify/dist/ReactToastify.css";
import TableComponent from "./elements/TableComponent";
import InactiveEmployeeModal from "./InactiveEmployeeModal";

class Users extends Component {
  constructor() {
    super();
    this.state = {
      projects: [],
      users: [],
      stateDown: true,
      searchTerm: "",
      openDetailModal: false,
      selectedUser: null,
      openConfirmatonModal: false,
      status: "Active",
    };
  }

  componentDidMount() {
    fetchProjects().then(async (response) => {
      const result = await response.json();
      this.setState({
        projects: result,
      });
    });
  }

  selectedStatusFilter = (status) => {
    this.setState({ status });
  };

  calculateCLLeaves = () => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const totalMonths = 12;
    const clPerMonth = 0.5;

    const remainingMonths = totalMonths - currentMonth;

    const remainingCL = remainingMonths * clPerMonth;

    return remainingCL;
  };

  changeUserStatus = (values) => {
    const { fetchUsers } = this.props;
    const { selectedUser } = this.state;
    let leavebalanceCL = 0;
    if (selectedUser.status === "Inactive" && values.status === "Active") {
      leavebalanceCL = this.calculateCLLeaves();
      console.log(leavebalanceCL)
    }
    updateUser(selectedUser._id, {
      ...selectedUser,
      ...values,
      leavebalanceCL: leavebalanceCL,
    }).then(async () => {
      await fetchUsers();
      toast.success("Employee has been successfully updated.");
      this.toggleConfirmationPopup(null);
    });
  };

  getProjectName = (userId) => {
    const { projects } = this.state;
    var selectedProjects = [];
    var selectedProjectsId = [];
    projects &&
      projects.map((project) => {
        project.teammember &&
          project.teammember.map((teammembers) => {
            if (
              teammembers.user === userId &&
              selectedProjectsId.indexOf(project._id) === -1
            ) {
              selectedProjects.push(project.projectname);
              selectedProjectsId.push(project._id);
            }
            return false;
          });
        return false;
      });
    return selectedProjects.join(",");
  };

  requestSearch = (searchTerm) => {
    this.setState({ searchTerm });
  };

  cancelSearch = () => {
    this.setState({ searchTerm: "" });
  };

  formatData = () => {
    const { users } = this.props;
    const { status } = this.state;
    if (!users || (users && users.length === 0)) return [];
    return (
      users &&
      users
        .filter((u) => u.status === status)
        .map((user, index) => {
          return {
            id: index + 1,
            firstName: user.fname,
            lastName: user.lname,
            status: user.status,
            role: userRole[user.role],
            projects: this.getProjectName(user._id),
            userId: user._id,
            data: user,
          };
        })
    );
  };

  toggleEmployeeDetail = (selectedUser) => {
    selectedUser &&
      fetchEmployeeFamilyMembers(selectedUser._id).then(async (response) => {
        var result = await response.json();
        this.setState({
          selectedUser: { ...selectedUser, familyMembers: result },
        });
      });
    this.setState((prev) => ({
      openDetailModal: !prev.openDetailModal,
    }));
  };

  toggleConfirmationPopup = (selectedUser) => {
    this.setState((prev) => ({
      openConfirmatonModal: !prev.openConfirmatonModal,
      selectedUser,
    }));
  };

  render() {
    const { users } = this.props;
    const { openDetailModal, selectedUser, searchTerm, openConfirmatonModal } =
      this.state;
    const formattedData = users && this.formatData();
    const filteredRows =
      formattedData &&
      formattedData.filter((row) => {
        return (
          (row &&
            row.firstName &&
            row.firstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (row &&
            row.lastName &&
            row.lastName.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (row &&
            row.projects &&
            row.projects.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (row &&
            row.email &&
            row.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (row &&
            row.role &&
            row.role.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      });
    const columns = [
      {
        id: "firstName",
        label: "First Name",
      },
      {
        id: "lastName",
        label: "Last Name",
      },
      {
        id: "projects",
        label: "Projects",
      },
      {
        id: "role",
        label: "Role",
      },
      {
        id: "status",
        label: "Status",
      },
    ];

    const actionMarkup = ({ data }) => (
      <React.Fragment>
        <Button
          color="link"
          className="text-info"
          onClick={() => this.toggleEmployeeDetail(data)}
        >
          <FontAwesomeIcon icon={faEye} className="mr-2" />
        </Button>
        <a href={`user-info/${data._id}`} title={"Edit"}>
          <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
        </a>
        <Button
          color="link"
          className="text-danger"
          onClick={() => this.toggleConfirmationPopup(data)}
        >
          <FontAwesomeIcon icon={faTimes} className="mr-2" />
        </Button>
      </React.Fragment>
    );

    return (
      <>
        <Row className="mt-5">
          <Col>
            {formattedData && (
              <TableComponent
                showCheckbox={false}
                columns={columns}
                data={filteredRows ? filteredRows : []}
                perPage={10}
                defaultSort="firstName"
                requestSearch={this.requestSearch}
                cancelSearch={this.cancelSearch}
                searchTerm={searchTerm}
                showSerialNo={true}
                selectedStatusFilter={this.selectedStatusFilter}
                action={actionMarkup}
              />
            )}
          </Col>
        </Row>

        <ToastContainer />
        <EmployeeDetailModal
          isOpen={openDetailModal}
          toggle={this.toggleEmployeeDetail}
          userDetails={selectedUser}
        />
        <InactiveEmployeeModal
          isOpen={openConfirmatonModal}
          toggle={this.toggleConfirmationPopup}
          userDetails={selectedUser && selectedUser}
          onSubmit={this.changeUserStatus}
        />
      </>
    );
  }
}

export default Users;
