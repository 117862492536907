import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import TeamMemberForm from './TeamMemberForm'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { filterUser } from '../../data/dataFromApis'
import { fetchActiveUsers, updateProject, fetchUser, updateUser } from '../../middleware/api'
import { fetchRoles } from '../../middleware/roles'

class TeamMemberModal extends React.Component {
    state = {
        users: null,
        roles: []
    }

    componentDidMount() {
        fetchActiveUsers()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    users: result
                })
            })
        fetchRoles()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    roles: result
                })
            })
    }
    onSubmit = (values) => {
        const { fetchProjects, toggleModal, project } = this.props
        project.teammember = values.teammember
        values.teammember && values.teammember.forEach(member => {
            fetchUser(member.user)
                .then(async (response) => {
                    var user = await response.json()
                    if (user && user.manager.indexOf(project.projectmanager) === -1) {
                        user.manager.push(project.projectmanager)
                        updateUser(user._id, user)
                            .then(async (res) => {

                            })
                    }
                })
        });
        updateProject(project._id, project)
            .then(async (response) => {
                await fetchProjects()
                toggleModal(null)
            })
    }
    render() {
        const { toggleModal, isOpen, project } = this.props
        const { users, roles } = this.state
        let adminUsers = users && filterUser(users, 'admin')
        let devUsers = users && filterUser(users, 'dev')
        let qaUsers = users && filterUser(users, 'qa')
        let managerUsers = users && filterUser(users, 'manager')
        const defMember = [{
            role: "",
            startdate: "",
            enddate: "",
            allocation: "",
            user: ""
        }]
        return (
            <div>
                <Modal size="lg" id="teammember-modal" isOpen={isOpen} toggle={toggleModal}>
                    <ModalHeader
                        tag="h2"
                        toggle={toggleModal}>Team Members</ModalHeader>
                    <ModalBody>
                        <Form
                            component={TeamMemberForm}
                            formId="teammember"
                            toggle={toggleModal}
                            onSubmit={this.onSubmit}
                            subscription={{
                                pristine: true,
                                submitError: true,
                                submitting: true,
                                hasValidationErrors: true,
                                values: true
                            }}
                            users={users && users}
                            roles={roles && roles.map(role => ({ value: role.role, label: role.role.toUpperCase() }))}
                            adminUsers={adminUsers && adminUsers}
                            devUsers={devUsers && devUsers}
                            qaUsers={qaUsers && qaUsers}
                            managerUsers={managerUsers && managerUsers}
                            mutators={{
                                ...arrayMutators,
                            }}
                            initialValues={{ teammember: project && project.teammember && project.teammember.length > 0 ? project.teammember : defMember }}
                        />

                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default TeamMemberModal;